'use client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useTranslations } from 'i11n';
import { requiredFieldMessage } from 'piramid-constants';
import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from 'utils';
import { z } from 'zod';

export const Providers = ({ children }: { children: React.ReactNode }) => {
  const [queryClient] = useState(() => new QueryClient());

  const t = useTranslations();

  useEffect(() => {
    z.setErrorMap((issue, ctx) => {
      if ('validation' in issue) {
        return {
          message: capitalizeFirstLetter(
            t(`zod.errors.invalid_string.${issue.validation}`, {
              validation: t(`zod.validations.${issue.validation}`),
            }),
          ),
        };
      }

      if (issue.code === z.ZodIssueCode.invalid_enum_value) {
        return {
          message: requiredFieldMessage,
        };
      }

      return {
        message: ctx.defaultError,
      };
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
